import React, { useCallback, useState, useEffect } from 'react';
import { Container, Row, Col, Modal, Form, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import jwt from 'jsonwebtoken';

import novartis from '../img/novartis.jpg';
import dmla from '../img/DMLA.png';
import omd from '../img/OMD.png';

const API_URI = `${process.env.REACT_APP_SSL_ENABLED === 'true' ? 'https' : 'http'}://${process.env.REACT_APP_API_URI}:${
  process.env.REACT_APP_SSL_ENABLED === 'true' ? process.env.REACT_APP_API_PORT_SSL : process.env.REACT_APP_API_PORT}/api`;
const SECRET = process.env.REACT_APP_JWT_SECRET;

const Client = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [wrongPassword, setWrongPassword] = useState(false);
  const [appChoice, setAppChoice] = useState('');
  const history = useHistory();

  const submitPassword = useCallback(async e => {
    e.preventDefault();
    const form = e.target;
    const pwd = form.password.value;
    try {
      const res = await axios.post(`${API_URI}/passwords/activate`, { game: appChoice, value: pwd });
      const { data } = res;
      const { token } = data;
      if (!token) {
        throw new Error('Invalid token');
      }
      // Keep token in localStorage
      localStorage.setItem(`license-${appChoice}`, token);
      // If the password was successfully activated, go to the game
      setWrongPassword(false);
      setShowPopup(false);
      history.push(`/game/${appChoice}`);
    } catch (error) {
      if (error.response && (error.response.status === 401)) {
        setWrongPassword(true);
      }
      console.log(error.message)
    }
  }, [appChoice, setWrongPassword, setShowPopup, history]);

  const openOMD = useCallback(() => {
    if (showPopup) {
      return;
    }
    setAppChoice('omd');
    setShowPopup(true)
  }, [setAppChoice, setShowPopup, showPopup]);

  const openDMLA = useCallback(() => {
    if (showPopup) {
      return;
    }
    setAppChoice('dmla');
    setShowPopup(true);
  }, [setAppChoice, setShowPopup, showPopup]);

  useEffect(() => {
    const license = localStorage.getItem(`license-${appChoice}`);
    if (license) {
      try {
        const { exp } = jwt.decode(license, SECRET);
        const dateNow = new Date();
        if (exp < dateNow.getTime() / 1000) {
          localStorage.removeItem(`license-${appChoice}`);
        } else {
          history.push(`/game/${appChoice}`);
        }
      } catch (error) {
        console.log(error)
        localStorage.removeItem(`license-${appChoice}`);
      }
    }
  }, [appChoice, history]);

  useEffect(() => {
    if (!showPopup) {
      setWrongPassword(false);
    }
  }, [showPopup, setWrongPassword]);

  return (
    <Container className='h-100 text-center'>
      <Col className='d-flex flex-column align-items-center h-100'>
      <Row className='justify-content-center mb-3'>
        <h1>Rétine Interactive</h1>
        <h4>Ateliers interactifs autour des examens inhérents au diagnostic de la DMLA et de l’OMD.</h4>
      </Row>
      <Row className='flex-grow-1 align-items-center p-3'>
        <Col className='' onClick={openDMLA}>
          <Row className='justify-content-center'><img className='app-logo' src={dmla} alt='DMLA' /></Row>
          <Row className='justify-content-center'><span style={{ fontWeight: 'bold', fontSize: 22, whiteSpace: 'nowrap' }}>La DMLA au cas par cas</span></Row>
          <Row className='justify-content-center' style={{visibility: 'hidden'}}><span className='app-ref'>G65364 – Octobre 2018 – © Novartis Pharma SAS</span></Row>
        </Col>
        <Col md={3} />
        <Col className='' onClick={openOMD}>
          <Row className='justify-content-center'><img className='app-logo' src={omd} alt='OMD' /></Row>
          <Row className='justify-content-center'><span style={{ fontWeight: 'bold', fontSize: 22, whiteSpace: 'nowrap' }}>L'œil du diabétique au cas par cas</span></Row>
          <Row className='justify-content-center'><span className='app-ref'>H65243 – Octobre 2019 – © Novartis Pharma SAS</span></Row>
        </Col>
      </Row>
      <img className='novartis' src={novartis} alt='Novartis' />
      </Col>

      <Modal show={showPopup} onHide={() => setShowPopup(false)} aria-labelledby='contained-modal-title-vcenter' centered>
        <Form className={wrongPassword ? 'invalid' : ''} onSubmit={submitPassword}>
          <Modal.Header closeButton><Modal.Title id='contained-modal-title-vcenter'>Connexion</Modal.Title></Modal.Header>
          <Modal.Body>
            <Form.Group controlId='password'>
              <Form.Label>Veuillez saisir le mot de passe qui vous a été fourni</Form.Label>
              <Form.Control type='password' required />
              <Form.Control.Feedback type='invalid'>Mot de passe erroné</Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button type='submit'>Valider</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Container>
  )
}

export default Client

import React from 'react'
import { Modal } from 'react-bootstrap'

const LegalNotice = props => {
  return (
    <Modal size='lg' {...props}>
      <Modal.Header closeButton><Modal.Title>Notice d’information sur la protection des données personnelles</Modal.Title></Modal.Header>
      <Modal.Body>
        <p>Cette Notice d’information sur la protection des données personnelles vous est adressée car vous visitez un site internet ou utilisez une application mobile («&nbsp;<strong>application</strong>&nbsp;») d’une des sociétés du groupe Novartis. Nous procédons au traitement d’informations vous concernant, informations qui constituent des «&nbsp;<strong>données à caractère personnel</strong>&nbsp;» et nous portons une grande attention à la protection de vos données personnelles et de votre vie privée.</p>
        <p>Novartis Pharma SAS décide pourquoi et comment un traitement de vos données personnelles est effectué et agit ainsi comme «&nbsp;<strong>responsable du traitement</strong>&nbsp;». Dans cette Notice d’information sur la protection des données personnelles, «&nbsp;<strong>nous</strong>&nbsp;» ou «&nbsp;<strong>notre</strong>&nbsp;» désignent Novartis Pharma SAS.</p>
        <p>Cette Notice d’information sur la protection des données personnelles est divisée en deux parties. La Partie I contient des informations pratiques sur les données personnelles spécifiques que nous traitons quand vous visitez notre site internet <a href='https://reunionscasclinique.troisprime.com/'>https://reunionscasclinique.troisprime.com/</a>, pourquoi nous les traitons et comment. La Partie II contient des informations générales sur les données personnelles standards techniques ou transactionnelles que nous traitons sur les visiteurs de nos sites internet et sur les utilisateurs de nos applications, la base légale nous permettant d’utiliser vos données personnelles, ainsi que vos droits relatifs au traitement de vos données personnelles.</p>
        <p>Nous vous invitons à lire attentivement cette Notice d’information sur la protection des données personnelles et, pour toute question supplémentaire, nous vous invitons à contacter votre responsable local de la protection des données personnelles, à l’adresse email <a href='mailto:droit.information@novartis.com' target='_blank' rel='noopener noreferrer'>droit.information@novartis.com</a>.</p>

        <h3 className='mt-5'>Partie I – Informations clé</h3>
        <p>Notre site internet <a href='https://reunionscasclinique.troisprime.com/'>https://reunionscasclinique.troisprime.com/</a> est une plateforme en ligne permettant aux délégués Novartis d'organiser des réunions professionnelles à distance avec des médecins ophtalmologues. Ces réunions concernent la résolution de cas cliniques médicaux portant sur la Dégénérescence Maculaire Liée à l'Âge ainsi que sur les pathologies oculaires liées au diabète.</p>
        <p>L’accès à la plateforme est destiné aux professionnels de santé. Pour cette raison, un mot de passe sera généré aléatoirement par participant. Ainsi, vous pourrez, grâce à ce mot de passe, vous connecter à la plateforme. Ce mot de passe ne peut être utilisé qu’une seule fois et a une durée de validité limitée de 3h renouvelable par l’agence 3 primes si nécessaire. Après réalisation des réunions, les mots de passe sont désactivés afin de garantir la protection du contenu de la plateforme.</p>

        <div className='border p-3 mb-3'>
          <h5><u>Données personnelles</u></h5>
          <p>Ce site internet n’a pas pour vocation de recueillir des données à caractère personnel.</p>
          <p>Lors de l’utilisation de cette plateforme, et notamment dans le cadre des cas cliniques qui vous sont proposés, vous pourrez être amené à renseigner des informations. Ces informations ne seront pas des données personnelles. Elles seront visibles par l’orateur pendant les réunions professionnelles et ne seront pas sauvegardées.</p>
          <p>Novartis Pharma SAS n’a pas accès aux informations renseignées.</p>
        </div>
        <div className='border p-3 mb-3'>
          <h5><u>Finalités spécifiques</u></h5>
          <p>Les informations que vous pourrez renseigner vont permettre de rendre interactives les réunions professionnelles auxquelles vous participez.</p>
        </div>
        <div className='border p-3 mb-3'>
          <h5><u>Tiers avec lesquels des informations sont partagées</u></h5>
          <p>Novartis Pharma SAS n’a accès à aucune de vos informations personnelles, ni aux réponses que vous pourrez renseigner.</p>
          <p>Seul l’orateur de la réunion professionnelle à laquelle vous participez aura accès aux réponses agrégées et anonymisées des cas cliniques proposés.</p>
        </div>
        <div className='border p-3 mb-3'>
          <h5><u>Durée de conservation</u></h5>
          <p>Le mot de passe généré ne sera valable que pour la réunion professionnelle pour lequel il a été créé et a une durée de validité de 3h renouvelable sur demande auprès de l’agence 3Prime.</p>
          <p>Les réponses aux cas cliniques proposés ne seront pas sauvegardées.</p>
        </div>
        <div className='border p-3 mb-3'>
          <h5><u>Point de contact</u></h5>
          <p>Si vous avez des questions concernant le traitement de vos données personnelles, veuillez contacter le responsable local de la protection des données personnelles, à l’adresse email <a href='mailto:droit.information@novartis.com' target='_blank' rel='noopener noreferrer'>droit.information@novartis.com</a></p>
        </div>

        <h3 className='mt-5'>Partie II – Informations générales</h3>
        <p>La Partie II de cette Notice d’information sur la protection des données personnelles donne plus de détails sur le contexte dans lequel nous sommes amenés à traiter vos données personnelles et explique vos droits et nos obligations.</p>
        <ol>
          <li className='font-weight-bold mt-3'>Sur quel fondement utilisons-nous vos données personnelles&nbsp;?</li>
          Nous traitons vos données personnelles uniquement si ce traitement est justifié et conforme aux dispositions légales en vigueur. Par conséquent, nous traiterons vos données personnelles uniquement si&nbsp;:
          <ul>
            <li>Nous avons obtenu votre consentement préalable&nbsp;;</li>
            <li>Le traitement est nécessaire à l’exécution d’un contrat auquel vous êtes partie ou à l’exécution de mesures précontractuelles prises à votre demande&nbsp;;</li>
            <li>Le traitement est nécessaire au respect de nos obligations légales ou règlementaires&nbsp;;</li>

            <li><p>Le traitement est nécessaire aux fins des intérêts légitimes que nous poursuivons et n’affecte pas indûment vos intérêts ou libertés et droits fondamentaux.</p>
            <p>Veuillez noter que quand nous traitons vos données personnelles sur ce dernier fondement, nous cherchons toujours à maintenir un équilibre entre nos intérêts légitimes et votre vie privée.</p>
            <p>Exemples d’«&nbsp;intérêts légitimes&nbsp;» pour lesquels des traitements de données sont effectués&nbsp;:</p>
            <ul>
              <li>Faire bénéficier de services de meilleure qualité (par exemple, nous pouvons choisir d’utiliser certaines plateformes mises en place par certains prestataires pour traiter des données)&nbsp;;</li>
              <li>Fournir nos services&nbsp;;</li>
              <li>Prévenir la fraude ou les activités criminelles, les mauvaises utilisations de nos services et assurer la sécurité de nos systèmes informatiques, nos architectures et nos réseaux&nbsp;;</li>
              <li>Vendre une partie de nos activités ou nos actifs ou permettre l’acquisition de tout ou partie de nos activités ou actifs par un tiers&nbsp;;</li>
              <li>Et respecter nos objectifs en matière de responsabilité sociale et organisationnelle.</li>
            </ul></li>
          </ul>

          <li className='font-weight-bold mt-3'>Qui a accès à vos données personnelles et à qui sont-elles transférées&nbsp;?</li>
          <p>Nous ne vendrons, ne partagerons, ni ne transférerons à des tiers aucune autre donnée personnelle vous concernant que celles indiquées dans cette Notice d’information.</p>
          <p>Dans le cadre de nos activités et pour les mêmes finalités que celles listées dans cette Notice, vos données personnelles peuvent être accessibles ou transférées aux catégories de destinataires identifiés dans la Partie I et aux catégories de destinataires suivants, dans la mesure où ils justifient d’un besoin pour accomplir ces finalités&nbsp;:</p>
          <ul>
            <li>Notre personnel (y compris le personnel, les départements ou les autres sociétés du groupe Novartis)&nbsp;;</li>
            <li>Nos autres fournisseurs et prestataires de services&nbsp;;</li>
            <li>Nos prestataires de systèmes d’information, nos prestataires de service Cloud, nos prestataires de bases de données et nos consultants&nbsp;;</li>
            <li>Nos partenaires commerciaux avec lesquels nous-mêmes ou les sociétés du groupe Novartis échangent des services&nbsp;;</li>
            <li>Tout tiers à qui nous avons assigné ou transféré nos droits ou obligations&nbsp;;</li>
            <li>Et nos conseillers ou avocats dans le cadre d’une vente ou d’un transfert de tout ou partie de nos activités ou actifs.</li>
          </ul>
          <p className='mt-3'>Les tiers listés ci-dessus sont contractuellement tenus de protéger la confidentialité et la sécurité de vos données personnelles en conformité avec la loi applicable.</p>
          <p>Vos données personnelles peuvent aussi être accessibles par, ou transférées à tout organisme de régulation, autorité étatique, juridiction ou organisme public national et/ou international, lorsque nous y sommes contraints par la loi ou les règlements, ou à leur demande.</p>
          <p>Les données personnelles vous concernant que nous collectons peuvent aussi être traitées, accessibles ou conservées dans un pays en dehors du pays où Novartis Pharma SAS est située, pays qui peut ne pas offrir le même niveau de protection des données personnelles.</p>

          <h6 className='font-weight-bold'>Transferts internationaux</h6>
          <p>Si nous transférons vos données personnelles à des sociétés situées dans d’autres juridictions, nous nous assurerons de protéger vos données personnelles
          <ol type='i'>
            <li>en appliquant le niveau de protection requis par les réglementations de protection des données personnelles et de la vie privée applicables à Novartis Pharma SAS,</li>
            <li>en agissant conformément à nos politiques internes et standards et,</li>
            <li>en transférant seulement vos données personnelles sur le fondement des clauses contractuelles types approuvées par la Commission européenne, sauf indication en sens contraire. Vous pouvez demander plus d’informations au sujet des transferts internationaux de données personnelles et obtenir une copie des protections adéquates mises en place en exerçant vos droits comme décrit à la Section 6 ci-dessous.</li>
          </ol></p>
          <p>Concernant les transferts de données personnelles aux autres sociétés du groupe, le groupe Novartis a adopté des Règles Internes d’Entreprise qui sont un système de principes, de règles et d’outils destiné à garantir des niveaux effectifs de protection des données personnelles en cas de transfert de données en dehors de l’Espace Economique Européen (c’est-à-dire, les Etats membres de l’Union européenne ainsi que l’Islande, le Liechtenstein et la Norvège, «&nbsp;EEE&nbsp;»), et de la Suisse. Pour en savoir plus sur les Règles Internes d’Entreprise de Novartis, cliquez sur le lien&nbsp;: <a href='https://www.novartis.fr/politique-de-confidentialite#regles' target='_blank' rel='noopener noreferrer'>https://www.novartis.fr/politique-de-confidentialite#regles</a>.</p>

          <li className='font-weight-bold mt-3'>Comment protégeons-nous vos données personnelles&nbsp;?</li>
          <p>Nous avons mis en place des mesures techniques et organisationnelles afin de garantir un niveau approprié de sécurité et de confidentialité à vos données personnelles.</p>
          <p>Ces mesures prennent en compte&nbsp;:
          <ol>
            <li>L’état de l’art des technologies&nbsp;;</li>
            <li>Le coût de leur mise en œuvre&nbsp;;</li>
            <li>La nature des données&nbsp;;</li>
            <li>Et le risque du traitement.</li>
          </ol></p>
          <p>La finalité qui en résulte est leur protection contre la destruction ou l’altération de manière accidentelle ou illicite, la perte accidentelle, la divulgation ou l’accès non autorisé et contre d’autres formes illicites de traitement.</p>
          <p>De plus, nous accomplissons les obligations suivantes&nbsp;:
            <ul>
              <li>Collecter et traiter uniquement les données personnelles qui sont adéquates, pertinentes et non excessives, en vue de poursuivre les finalités déterminées ci-dessus&nbsp;;</li>
              <li>Assurer que vos données personnelles sont à jour et exactes (pour ce faire, nous pouvons vous demander de confirmer l’exactitude des données personnelles que nous détenons à votre sujet. Vous êtes aussi invité à nous informer de façon spontanée de tout changement dans votre situation personnelle afin que nous puissions nous assurer que vos données personnelles sont mises à jour)&nbsp;;</li>
              <li>Et nous pouvons traiter des données sensibles vous concernant que vous nous avez volontairement fournies conformément aux lois de protection des données applicables et uniquement pour les finalités énoncées ci-dessus, sous la responsabilité de l’un de nos représentants soumis à une obligation de secret professionnel ou de confidentialité.</li>
            </ul>
          </p>

          <li className='font-weight-bold mt-3'>Combien de temps conservons-nous vos données personnelles&nbsp;?</li>
          <p>Nous conservons vos données personnelles uniquement le temps nécessaire afin d’accomplir la finalité pour laquelle elles ont été collectées ou pour remplir des obligations légales ou règlementaires.</p>
          <p>Sauf indication contraire dans la Partie I de cette Notice d’information sur la protection des données personnelles, la durée de conservation est de 24 mois suivant votre dernière utilisation ou dernier accès au site concerné ou à notre application. A l’issue du délai de conservation, vos données personnelles seront supprimées de nos systèmes actifs.</p>

          <li className='font-weight-bold mt-3'>Comment utilisons-nous les cookies ou les technologies similaires sur nos sites internet et applications&nbsp;?</li>
          <ol>
            <li className='font-weight-bold mt-2'>Cookies</li>
            <p>Les cookies sont de petits fichiers texte qui sont déposés et/ou lus sur votre équipement terminal (ordinateur, tablette, téléphone portable, etc.) quand vous visitez nos sites internet ou quand vous utilisez nos applications. Nous utilisons des cookies pour les finalités susmentionnées et conformément à cette Notice d’information sur la protection des données personnelles.</p>
            <p>Nous n’utilisons pas des cookies pour tracer individuellement les visiteurs ou pour vous identifier mais afin d’obtenir des connaissances nécessaires pour comprendre comment nos sites internet et applications sont utilisés, dans le but de les améliorer au bénéfice de nos utilisateurs. Les données personnelles générées grâce aux cookies sont collectées de manière pseudonyme et vous avez le droit de vous opposer à ce traitement, comme indiqué ci-dessous.</p>
            <p>En plus des cookies listés à la Partie I de la Notice d’information sur la protection des données personnelles, nous pouvons aussi utiliser les types de cookies suivants&nbsp;:
              <ul>
                <li>Des cookies pour la personnalisation de l’interface utilisateur (c’est-à-dire, des cookies permettant de mémoriser vos préférences)&nbsp;;</li>
                <li>Des cookies d’authentification (c’est-à-dire, des cookies vous permettant de quitter ou de retourner sur nos sites internet sans avoir à vous authentifier de nouveau)&nbsp;;</li>
                <li>Des cookies pour lire des vidéos (c’est-à-dire, des cookies enregistrant les données nécessaires pour repasser le contenu vidéo ou audio et enregistrer vos préférences)&nbsp;;</li>
                <li>Nos cookies analytics (c’est-à-dire, des cookies mémorisant les pages que vous visitez et donnant des informations sur votre interaction avec ces pages)&nbsp;;</li>
                <li>Et les cookies analytics de tiers (c’est-à-dire, des cookies de fournisseurs tiers traçant vos statistiques sur nos sites internet et vice versa).</li>
              </ul>
            </p>
            <p>Veuillez noter que vous pouvez modifier votre navigateur pour qu’il vous informe quand des cookies lui sont envoyés. Si vous ne voulez pas recevoir des cookies, vous pouvez aussi refuser tous les cookies en activant les paramètres correspondants de votre navigateur. Enfin, vous pouvez aussi supprimer les cookies qui ont déjà été installés.</p>
            <p>Pour plus d’information sur la manière dont sont gérés les cookies sur votre appareil, veuillez consulter la fonction Aide de votre navigateur ou visiter le site internet http://www.allaboutcookies.org/fr/, qui contient des informations détaillées sur la gestion de vos cookies sur différentes sortes de navigateurs (lien externe).</p>

            <li className='font-weight-bold'>Autres technologies</li>
            <p>Nous pouvons aussi utiliser d’autres technologies sur nos sites internet et applications afin de collecter et traiter vos données personnelles pour les mêmes finalités que celles indiquées ci- dessus, y compris&nbsp;:
              <ul>
              <li>Des balises internet (tels que les balises d’action, des GIFs single-pixel, des clear GIFs, des GIFs invisibles et des GIFs 1-by-1, qui sont des technologies nous permettant de tracer le parcours des utilisateurs sur nos sites internet)&nbsp;;</li>
              <li>Et la technologie Adobe Flash (y compris les cookies Flash, à moins que vous en ayez décidé autrement dans vos paramètres).</li>
              </ul>
            </p>
          </ol>

          <li className='font-weight-bold mt-3'>Quels sont vos droits et comment les exercer&nbsp;?</li>
          <p>Vous pouvez exercer les droits suivants dans les conditions et selon les limites prévues par la loi&nbsp;:
            <ul>
              <li>Accéder à l’ensemble de vos données&nbsp;: vous pouvez obtenir des informations relatives au traitement de vos données ainsi qu’une copie de celles-ci&nbsp;;</li>
              <li>Rectifier et mettre à jour vos données personnelles si vous pensez par exemple qu’une de vos informations est incorrecte, obsolète ou incomplète&nbsp;;</li>
              <li>Effacer&nbsp;: vous pouvez demander la suppression de vos données personnelles&nbsp;;</li>
              <li>Demander une limitation des traitements des informations&nbsp;;</li>
              <li>Vous opposer au traitement de vos données personnelles (pour tout ou partie)&nbsp;;</li>
              <li>Retirer votre consentement à tout moment pour le traitement de vos données soumis à votre consentement, sans que cela n’affecte la licéité du traitement effectué avant ce retrait&nbsp;;</li>
              <li>Donner des instructions sur le sort de vos données après votre décès&nbsp;;</li>
              <li>Demander la portabilité c’est-à-dire que les données personnelles que vous nous avez fournies vous soient restituées ou transférées à une personne de votre choix, dans un format structuré, couramment utilisé et lisible par machine.</li>
            </ul>
          </p>
          <p>Veuillez noter cependant que, dans certains cas, votre refus d’accepter les cookies ou le paramétrage de votre navigateur peut affecter votre expérience de navigation et peut vous empêcher d’utiliser certaines fonctions de nos sites internet ou applications.</p>
          <p>Si vous avez une question ou si vous souhaitez exercer vos droits décrits ci-dessus, vous pouvez envoyer un email à l’adresse <a href='mailto:droit.information@novartis.com' target='_blank' rel='noopener noreferrer'>droit.information@novartis.com</a>. Une photocopie de votre pièce d’identité pourra vous être demandée afin de vous identifier, étant entendu que nous l’utilisons uniquement pour vérifier votre identité et que nous ne conservons pas cette photocopie après vérification de votre identité. Quand vous nous envoyez cette photocopie, veillez à occulter votre photographie et votre numéro national d’immatriculation ou son équivalent.</p>
          <p>Si vous n’êtes pas satisfait de la façon dont nous traitons vos données personnelles, vous pouvez adresser une réclamation à notre délégué à la protection des données à l’adresse email <a href='mailto:global.privacy_office@novartis.com' target='_blank' rel='noopener noreferrer'>global.privacy_office@novartis.com</a>, qui étudiera vos demandes.
          Dans tous les cas, vous avez aussi le droit de déposer plainte auprès des autorités de protection des données compétentes (pour la CNIL&nbsp;: <a href='https://www.cnil.fr' target='_blank' rel='noopener noreferrer'>www.cnil.fr</a>).</p>

          <li className='font-weight-bold mt-3'>Quelles sont les données techniques et transactionnelles que nous pouvons collecter à votre sujet&nbsp;?</li>
          <ol>
            <li className='font-weight-bold mt-2'>Catégories de données techniques et transactionnelles</li>
            <p>En plus de toutes les informations que nous collectons vous concernant au titre de la Partie I de cette Notice d’information sur la protection des données personnelles, nous pouvons collecter différentes sortes de données personnelles techniques et transactionnelles à votre sujet, données nécessaires pour assurer le bon fonctionnement de nos sites internet et applications, y compris&nbsp;:
              <ul>
                <li>Des informations relatives à votre navigateur et votre appareil (par exemple, le nom de domaine de votre fournisseur d’accès internet, le type et la version de votre navigateur, votre système d’exploitation et plateforme, votre résolution écran, le fabriquant et le modèle de votre appareil)&nbsp;;</li>
                <li>Des statistiques concernant l’utilisation de notre site internet ou application (par exemple, des informations sur les pages visitées, les recherches effectuées, le temps passé sur notre site internet)&nbsp;;</li>
                <li>Des données d’usage (c’est-à-dire la date et la durée d’accès à notre site internet ou application, les documents téléchargés)&nbsp;;</li>
                <li>La localisation de votre appareil quand vous utilisez notre application, dans les conditions prévues par la réglementation et à moins que vous n’ayez désactivé cette fonction en changeant les paramètres de votre navigateur&nbsp;;</li>
                <li>Et de manière plus générale, toute information que vous nous fournissez en utilisant notre site internet ou application.</li>
              </ul>
            </p>
            <p>Veuillez noter que sciemment, nous ne collecterons, n’utiliserons ou ne divulguerons pas les données personnelles d’un mineur âgé de 15 ans sans obtenir préalablement le consentement de son parent ou d’un représentant légal.</p>

            <li className='font-weight-bold'>Pourquoi collectons-nous des données techniques et transactionnelles&nbsp;?</li>
            Nous traitons toujours vos données personnelles pour une finalité déterminée et uniquement si ces données sont pertinentes et nécessaires pour atteindre celle-ci. En plus de toutes les finalités qui vous ont déjà été communiquées dans la Partie I de cette Notice d’information sur la protection des données personnelles, nous procédons aussi au traitement de vos données personnelles collectées pendant votre utilisation de nos sites internet ou applications pour les finalités standards suivantes&nbsp;:
            <ul>
              <li>Gérer les utilisateurs (par exemple, enregistrement, gestion des comptes, réponse aux questions et fournir une aide technique)&nbsp;;</li>
              <li>Gérer et améliorer nos sites internet et applications (par exemple, diagnostic des problèmes serveur, optimisation du trafic, intégration et optimisation des pages internet si nécessaire)&nbsp;;</li>
              <li>Mesurer l’utilisation de nos sites internet et applications (par exemple, en préparant des statistiques relatives au trafic, en recueillant des informations sur les comportements des utilisateurs et les pages qu’ils visitent)&nbsp;;</li>
              <li>Améliorer et personnaliser votre expérience, adapter le contenu en fonction de vos préférences (par exemple, en enregistrant vos sélections et préférences ou en utilisant des cookies)&nbsp;;</li>
              <li>Vous envoyer des services personnalisés et du contenu&nbsp;;</li>
              <li>Améliorer la qualité de nos services et étendre nos activités commerciales&nbsp;;</li>
              <li>Surveiller et prévenir la fraude, les violations et toute autre potentielle mauvaise utilisation
              de nos sites internet et applications&nbsp;;</li>
              <li>Répondre à une requête officielle d’une autorité publique ou judiciaire avec l’autorisation
              nécessaire&nbsp;;</li>
              <li>Gérer nos ressources informatiques, y compris la gestion des infrastructures et la continuité des affaires&nbsp;;</li>
              <li>Protéger les intérêts économiques de notre société, assurer la conformité de nos activités et le signalement des abus (notamment la conformité avec nos politiques internes et les exigences légales locales, le respect de nos obligations fiscales, la gestion des cas allégués d’abus ou agissements frauduleux&nbsp;; mener des activités d’audits et se défendre dans le cadre d’un litige)&nbsp;;</li>
              <li>Conservation des archives et des enregistrements&nbsp;;</li>
              <li>Et toute autre finalité imposée par la loi et les autorités.</li>
            </ul>
          </ol>
          <li className='font-weight-bold mt-3'>Comment serez-vous informé des changements survenus dans notre Notice d’information sur la protection des données personnelles&nbsp;?</li>
          <p>Vous serez informé de tous futurs changements ou ajouts concernant le traitement de vos données personnelles décrit dans cette Notice d’information à travers nos canaux de communication habituels (par exemple, par email) ou à travers nos sites internet ou applications (via des bannières, des pop-ups ou autres mécanismes de notification).</p>
        </ol>
      </Modal.Body>
    </Modal>
  )
}

export default LegalNotice

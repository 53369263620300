import React, { useEffect, useState, useCallback } from 'react';
import { Route, Redirect } from 'react-router-dom';
import jwt from 'jsonwebtoken';
import axios from 'axios';

const API_URI = `${process.env.REACT_APP_SSL_ENABLED === 'true' ? 'https' : 'http'}://${process.env.REACT_APP_API_URI}:${
  process.env.REACT_APP_SSL_ENABLED === 'true' ? process.env.REACT_APP_API_PORT_SSL : process.env.REACT_APP_API_PORT}/api`;
const SECRET = process.env.REACT_APP_JWT_SECRET;

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { computedMatch: { params } } = rest;
  const [isAuthenticated, setIsAuthenticated] = useState(null)

  const authenticateIfSessionActive = useCallback(async (id, token) => {
    try {
      const res = await axios.get(`${API_URI}/sessions/${id}`, { headers: { 'x-auth-token': token } });
      const { data: session } = res;
      if (!session.active) {
        localStorage.removeItem(`license-${params.slug}`);
      }
      setIsAuthenticated(session.active)
    } catch (error) {
      setIsAuthenticated(false)
    }
  }, [params, setIsAuthenticated])

  useEffect(() => {
    const license = localStorage.getItem(`license-${params.slug}`)
    if (license) {
      try {
        const credentials = jwt.decode(license, SECRET);
        const { exp, game, session } = credentials;

        const dateNow = new Date();
        if (game !== params.slug || exp < dateNow.getTime() / 1000) {
          // TODO: show message before redirection
          localStorage.removeItem(`license-${params.slug}`);
          setIsAuthenticated(false)
        } else {
          authenticateIfSessionActive(session, license)
        }
      } catch (error) {
        console.log(error)
        localStorage.removeItem(`license-${params.slug}`);
        setIsAuthenticated(false)
      }
    } else {
      setIsAuthenticated(false)
    }
  }, [params, authenticateIfSessionActive, setIsAuthenticated])

  if (isAuthenticated === null) {
    return null;
  }

  return (
    <Route {...rest} render={props => !isAuthenticated ? <Redirect to='/' /> : <Component {...props} />}
    />
  );
};

export default PrivateRoute;
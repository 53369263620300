import React, { useEffect, useState } from 'react';
import Unity, { UnityContent } from 'react-unity-webgl';
import jwt from 'jsonwebtoken';
import { useRef } from 'react';
import { useCallback } from 'react';
import { Button, Spinner } from 'react-bootstrap';

const PROTOCOL = process.env.REACT_APP_SSL_ENABLED === 'true' ? 'https' : 'http';
const GAME_SERVER_URI = process.env.REACT_APP_GAME_SERVER_URI;
const GAME_PORT = process.env.REACT_APP_SSL_ENABLED === 'true' ? process.env.REACT_APP_GAME_PORT_SSL : process.env.REACT_APP_GAME_PORT;
const SECRET = process.env.REACT_APP_JWT_SECRET;

const Game = (props) => {
  const {
    match: { params },
  } = props;
  const [unityContent, setUnityContent] = useState(null);
  const [ready, setReady] = useState(false);
  const [width, setWidth] = useState('100%');
  const [height, setHeight] = useState(600);
  const [isLoading, setIsLoading] = useState(true);
  const [progression, setProgression] = useState(0);
  const container = useRef(null);
  const loading = useRef(null);

  const logout = useCallback(() => {
    localStorage.removeItem('token');
    localStorage.removeItem(`license-${params.slug}`);
    window.location.reload();
  }, [params.slug]);

  const updateDimensions = useCallback(() => {
    if (container.current) {
      const parentStyle = getComputedStyle(container.current.parentNode);
      const parentHeight = container.current.parentNode.clientHeight - parseFloat(parentStyle.paddingTop) - parseFloat(parentStyle.paddingBottom);
      const newHeight = (container.current.offsetWidth * 600) / 800;
      if (newHeight >= parentHeight) {
        setHeight(parentHeight);
        setWidth((parentHeight * 800) / 600);
      } else {
        setHeight(newHeight);
      }
    }
  }, [container, setWidth, setHeight]);

  const updateLoading = useCallback(
    (p) => {
      if (!isLoading) {
        return;
      }
      setProgression(p);
      if (loading.current !== null && p >= 1) {
        loading.current.classList.add('fade-out');
        setTimeout(() => setIsLoading(false), 500);
      }
    },
    [loading, isLoading, setProgression, setIsLoading]
  );

  useEffect(() => {
    setUnityContent(new UnityContent(`/games/${params.slug}/build.json`, '/games/UnityLoader.js', { adjustOnWindowResize: true }));
  }, [params]);
  useEffect(() => {
    const makeReady = () => setReady(true);
    if (unityContent) {
      unityContent.on('PlayerReady', makeReady);
      unityContent.on('progress', updateLoading);
    }
  }, [unityContent, setReady, setProgression, updateLoading]);

  useEffect(() => {
    const license = localStorage.getItem(`license-${params.slug}`);
    if (ready && license) {
      try {
        const credentials = jwt.decode(license, SECRET);
        const { exp, session, admin } = credentials;
        const dateNow = new Date();
        if (exp < dateNow.getTime() / 1000) {
          localStorage.removeItem(`license-${params.slug}`);
          window.location.reload();
        } else {
          // Join the room
          unityContent.send('NetworkManager', 'InitSocket', JSON.stringify({ room: session, protocol: PROTOCOL, server: GAME_SERVER_URI, port: GAME_PORT, admin: !!admin }));
        }
      } catch (error) {
        console.log(error);
        localStorage.removeItem(`license-${params.slug}`);
        window.location.reload();
      }
    }
    // }
  }, [ready, unityContent, params]);

  useEffect(() => {
    if (container.current) {
      updateDimensions();
      window.addEventListener('resize', updateDimensions, false);
      return () => {
        window.removeEventListener('resize', updateDimensions, false);
      };
    }
  }, [container, setWidth, setHeight, updateDimensions]);

  return (
    <div ref={container} style={{ width, height, maxHeight: '100%', maxWidth: '100%', margin: 'auto', position: 'relative' }}>
      <Button variant='secondary' className='ml-2' style={{ position: 'fixed', top: 10, right: 10, zIndex: 100 }} onClick={logout}>
        Déconnexion
      </Button>
      {unityContent && <Unity unityContent={unityContent} />}
      {isLoading && (
        <div ref={loading} className='d-flex flex-column justify-content-center align-items-center bg-dark text-white position-absolute left-0' style={{ left: 0, top: 0, right: 0, bottom: 0 }}>
          <Spinner animation='border' role='status'>
            <span className='sr-only'>Loading...</span>
          </Spinner>
          <p className='mt-3' style={{ fontSize: 30 }}>{`${(progression * 100).toFixed()} %`}</p>
        </div>
      )}
    </div>
  );
};

export default Game;

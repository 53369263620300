import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import './App.css';

import Client from './components/Client';
import Admin from './components/Admin';
import PrivateRoute from './components/PrivateRoute';
import Game from './components/Game';
import LegalNotice from './components/LegalNotice';

const App = () => {
  const [showInfo, setShowInfo] = useState(false);

  return (
    <div className='App'>
      <Router>
        <Switch>
          <PrivateRoute exact path='/game/:slug' component={Game} />
          <Route exact path='/admin' component={Admin} />
          <Route exact path='/' component={Client} />
          <Redirect from="*" to="/" />
        </Switch>
      </Router>
      <footer onClick={() => setShowInfo(true)}>Information sur la protection des données personnelles</footer>
      <LegalNotice show={showInfo} onHide={() => setShowInfo(false)} />
    </div>
  );
}

export default App;
